.card,
.card-header,
.hero,
body {
  background-color: #ffffff;
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color-1);
}

.hero {
  border-bottom-color: var(--skin-background-color-1);
}

.banner-color {
  background-color: var(--skin-banner-background-color-1);
}

.header-banner {
  background-color: var(--skin-banner-background-color-2);
  color: var(--skin-banner-text-color-1);
}

.header-banner .close-button .close {
  background-color: var(--skin-primary-color-1);
}

h1.header.page-title,
h1.header.page-title:before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert);
}

.product-tile {
  background-color: #ffffff;
  border: 10px solid #ffffff;
}

.refinements ul li button {
  color: var(--skin-primary-color-1);
}

.custom-select {
  background: #f5f5f5;
  color: var(--skin-selectbox-text-color-1);
}

a,
header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: var(--skin-primary-color-1);
}

.price {
  color: var(--skin-price-1);
}

.dropdown-menu,
.dropdown-menu a,
.navbar,
.navbar .nav-item,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.navbar .nav-item a {
  color: #000;
}

@media (min-width: 769px) {
  .dropdown-menu,
.dropdown-menu a,
.navbar,
.navbar .nav-item,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.navbar .nav-item a {
    color: var(--skin-menu-color-1-invert);
  }
}
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: #ffffff;
}

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: #ffffff;
}

.navbar .nav-item.dropdown .dropdown-menu.show .nav-item,
.navbar .nav-item.dropdown .dropdown-menu.show a {
  color: var(--skin-selectbox-text-color-1);
}

.nav-item .nav-link:focus,
.nav-item .nav-link:hover,
.nav-item.show .nav-link {
  color: var(--skin-menu-color-1) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color);
  color: var(--skin-primary-color-invert-1);
  opacity: 0.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--skin-header-font), sans-serif;
}

a {
  color: var(--skin-link-color-1);
}